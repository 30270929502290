import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import ServiceSectionWrapper from './service.style';
import Button from 'common/src/components/Button';
import Fade from 'react-reveal/Fade';
import rocketStraight from 'common/src/assets/image/saas/rocketStraight.svg';
import {
  BackgroundShadow1,
  BackgroundShadow2,
  FeatureBlockWrapper,
} from './service.style';

const ServiceSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
  outlineBtnStyle,
  buttonBox,
  divRocketImg,
  rocketImg,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Service {
          id
          title
          description
          icon
          otherIcon
          link
        }
      }
    }
  `);

  useEffect(() => {
    if (window) {
      var square1 = document.getElementById('testtt');
      window.addEventListener('scroll', () => {
        var scrollTop = window.pageYOffset || window.scrollTop;
        if (square1 && square1.style) {
          square1.style.transform = `translateY(${scrollTop * -0.8}px)`;
        }
      });
    }
    return () => window.removeEventListener('scroll', () => {});
  }, []);

  return (
    <ServiceSectionWrapper id="feature_section">
      {/* <BackgroundShadow1 />
      <BackgroundShadow2 /> */}
      <Container>
        <Box {...sectionHeader}>
          <Text content="SIX CORE FEATURES" {...sectionSubTitle} />
          <Heading
            content="Go beyond account-based marketing"
            {...sectionTitle}
          />
        </Box>
        <Box className="row" {...row}>
          {Data.saasJson.Service.map((feature, index) => (
            <Box className="col" {...col} key={index}>
              <Fade up delay={100 * index + 1} key={`key-feature-key${index}`}>
                <FeatureBlockWrapper
                  onClick={() => {
                    window.open(feature.link, '_self');
                  }}
                >
                  <FeatureBlock
                    otherIcon={feature.otherIcon}
                    icon={<i className={feature.icon} />}
                    wrapperStyle={blockWrapperStyle}
                    iconStyle={iconStyle}
                    contentStyle={contentStyle}
                    title={
                      <Heading content={feature.title} {...featureTitle} />
                    }
                    description={
                      <Text
                        content={feature.description}
                        {...featureDescription}
                      />
                    }
                    className="saasService"
                  />
                </FeatureBlockWrapper>
              </Fade>
            </Box>
          ))}
        </Box>

        <Box {...buttonBox}>
          <Button
            className="outlined"
            title="EXPLORE ALL FEATURES"
            variant="outlined"
            onClick={() => window.open('/features', '_self')}
            {...outlineBtnStyle}
          />
        </Box>
      </Container>
      {/* <div style={divRocketImg} id="testtt">
        <img src={rocketStraight} style={rocketImg} alt="rocket" />
      </div> */}
    </ServiceSectionWrapper>
  );
};

// ServiceSection style props
ServiceSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
  buttonBox: PropTypes.object,
  divRocketImg: PropTypes.object,
  rocketImg: PropTypes.object,
};

// ServiceSection default style
ServiceSection.defaultProps = {
  divRocketImg: {
    top: '821px',
    left: '90%',
    transition: 'transform 0s linear 0s',
    willChange: 'transform',
    position: 'absolute',
    zIndex: -1,
    width: '8%',
    WebkitFontSmoothing: 'antialiased',
  },
  rocketImg: {
    verticalAlign: 'top',
    maxWidth: '100%',
    height: 'auto',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    opacity: '0.9',
  },
  buttonBox: {
    flexBox: true,
    display: 'flex',
    justifyContent: 'center',
    mb: ['60px', '60px', '60px', '80px'],
    mt: ['60px', '60px', '60px', '80px'],
  },
  // section header default style
  sectionHeader: {
    mb: ['50px', '50px', '50px', '80px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#5167db',
    justifyContent: 'center',
    type: 'button',
    colors: 'primary',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#2a313c',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '80px'],
    height: ['70px', '80px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: '30px',
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
    mt: '-5px',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#2a313c',
    lineHeight: '1.5',
    letterSpacing: '-0.020em',
    mb: '10px',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
};

export default ServiceSection;
