import React from 'react';
import PropTypes from 'prop-types';
import Zoom from 'react-reveal/Zoom';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './visitor.style';
import TextHr from 'common/src/components/textLine';
import ImageBg from 'common/src/assets/image/saas/visitor_bg.png';
import AutomationImgSlider from 'common/src/components/automationImgSlider';
import Fade from 'react-reveal/Fade';

const VisitorSection = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  sectionSubTitle,
}) => {
  return (
    <VisitorSectionWrapper id="visitorSection">
      <SectionObject>
        <Card className="objectWrapper" {...imageWrapper}>
          <Zoom>
            <Image src={ImageBg} alt="BgImage" />
          </Zoom>
          <Card className="dashboardWrapper" {...imageWrapper}>
            <Fade left delay={90}>
              <AutomationImgSlider />
            </Fade>
          </Card>
        </Card>
      </SectionObject>
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={
              <>
                <TextHr />
                <Text content="ENGAGEMENT AUTOMATION" {...sectionSubTitle} />
                <Heading
                  content="Scale up sales activities and enjoy automatization."
                  {...title}
                />
              </>
            }
            description={
              <Text
                content="Don't miss any opportunity by leveraging intelligent sequences that helps your team engage with the right prospects using the right strategy!"
                {...description}
              />
            }
            button={
              <Button
                title="HOW IT WORKS"
                {...btnStyle}
                onClick={() => window.open('/features#automation', '_self')}
              />
            }
          />
        </Box>
      </Container>
    </VisitorSectionWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0, '58%'],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
    marginTop: ['2rem', '3.5rem'],
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
